import * as React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql } from "gatsby"

import { COLOR_MAIN, COLOR_WHITE } from "style/colors"
import { CONTENT_SIZE } from "style/size"

import { Layout } from "components/Layout"

import { TitleMain as BaseTitleMain } from "components/text/TitleMain"
import { Title } from "components/text/Title"
//import { Text } from "components/text/Text"
import { Caption } from "components/text/Caption"

import { NextButton } from "components/interactable/NextButton"
import { Carousel } from "components/interactable/Carousel"
import { Gallery } from "components/interactable/Gallery"

import { Box } from "components/elements/Box/Box"
import { ArticleCard } from "components/elements/ArticleCard/ArticleCard"
import { ContentPortableText } from "components/blockContent/ContentPortableText"
import {
	getLinkPropsFromRawData,
	//getRawDataLinkTo,
	linkRawDataHasFields,
} from "linkFunctions"



const Image = styled(Img)`
	/*flex: 1;*/
`

const OuterBlock = styled.div`
	margin-bottom: 40px;
	padding: 0 24px;
`

const InnerBlock = styled.div`
	max-width: ${CONTENT_SIZE}px;
	margin-left: auto;
	margin-right: auto;
`

const HeadingBlock = styled.div`
	background-color: ${COLOR_MAIN};
	padding: 0;
	> * {
		/*background-color: ${COLOR_MAIN};*/
		padding: 0 24px;
		margin-left: auto;
		margin-right: auto;
	}
`

const Item = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
`

const Triangle = styled.div`
	max-width: unset;
	position: relative;
	/*top: -1px;*/
	display: block;
	width: 100%;
	height: 100px;
	background-color: ${COLOR_WHITE};
	/*clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 40%);*/
	clip-path: polygon(0 0, 0 100%, 100% 100%);
	@media screen and (min-width: 800px) {
		height: 200px;
	}
`

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow: auto;
`

const ContentInner = styled.div`
	position: relative;
	max-width: ${CONTENT_SIZE}px;
	width: 100%;
`

const TitleMain = styled(BaseTitleMain)`
	@media screen and (min-width: 800px) {
		position: absolute;
		bottom: 100px;
		right: 50px;
		text-shadow: 0px 0px 1px rgb(0 0 0 / 80%), 0px 0px 2px rgb(0 0 0 / 50%),
			4px 4px 4px rgb(0 0 0 / 50%);
	}
`

const Lighter = styled.span`
	display: block;
	text-align: right;
	font-weight: 500;
`

const Block = ({ children }) => (
	<OuterBlock>
		<InnerBlock>{children}</InnerBlock>
	</OuterBlock>
)

/**
 * This should just be a normal template with the added title- and title2-fields.
 * @param {*} param0
 * @returns
 */
const IndexPage = ({ data: { sanityFrontPage } }, { location } ) => {
	console.log({ sanityFrontPage })

	const {
		title,
		title_secondary,
		box,
		description,
		splitBox,
		images,
		gallery,
		//meta,
	} = sanityFrontPage || {}

	const decriptionLinkProps = getLinkPropsFromRawData(
		description?.link?._rawData
	)
	console.log({ decriptionLinkProps })

	console.log({ rawData: description?.link?._rawData })

	console.log({ box, raw: box?._rawText })

	return (
		<Layout>
			<HeadingBlock>
				<Content>
					<ContentInner>
						<Carousel itemCount={gallery?.length}>
							{gallery?.map(item => {
								const { _key, alt, caption, image } = item || {}
								return (
									<Item key={_key}>
										{image && (
											<Image
												fluid={image?.asset?.fluid}
												alt={alt}
												loading="eager"
											/>
										)}
										{caption && (
											<Caption>{caption}</Caption>
										)}
									</Item>
								)
							})}
						</Carousel>
{/* 						<Breadcrumb
							location={location}
							crumbLabel="Home"
							crumbStyle={{ color: "#666" }}
							crumbActiveStyle={{ color: "orange" }}
						/> */}

						<TitleMain>
							{title} <Lighter>{title_secondary}</Lighter>
						</TitleMain>
					</ContentInner>
				</Content>
				<Triangle />
			</HeadingBlock>
			<Block>
				<Title as="h2">{description?.title}</Title>
				{description?._rawText && (
					<ContentPortableText blocks={description._rawText} />
				)}
				{linkRawDataHasFields(description?.link?._rawData) && (
					<NextButton
						alt={description?.link?.label}
						{...getLinkPropsFromRawData(description.link._rawData)}
					/>
				)}
			</Block>
			<Block>
				<Gallery
					items={images?.cards?.map(item => {
						return { ...item, text: item?._rawText }
					})}
				/>
			</Block>
			<Block>
				<Box
					title={box?.title}
					text={box?._rawText}
					buttonText={box?.link?.label}
					buttonTo={box?.link?._rawData}
				/>
			</Block>

			<Block>
				<ArticleCard
					title={splitBox?.title}
					content={splitBox?._rawText}
					image={{
						image: {
							asset: {
								fluid: splitBox?.image?.image?.asset?.fluid,
							},
						},
						alt: splitBox?.image?.alt,
					}}
					links={splitBox?.links}
				/>
			</Block>
		</Layout>
	)
}

export const query = graphql`
	query GetFrontPageElements {
		sanityFrontPage {
			title
			title_secondary
			gallery {
				_key
				alt
				caption
				image {
					asset {
						label
						fluid {
							src
							aspectRatio
							base64
							sizes
							srcSet
							srcSetWebp
							srcWebp
						}
					}
				}
			}
			description {
				title
				_rawText(resolveReferences: { maxDepth: 10 })
				link {
					label
					_rawData(resolveReferences: { maxDepth: 10 })
				}
			}
			splitBox {
				title
				image {
					alt
					caption
					image {
						asset {
							fluid {
								src
								aspectRatio
								base64
								sizes
								srcSet
								srcSetWebp
								srcWebp
							}
						}
					}
				}
				_rawText(resolveReferences: { maxDepth: 10 })
				links {
					label
					_rawData(resolveReferences: { maxDepth: 10 })
				}
			}
			images {
				cards {
					_key
					title
					image {
						alt
						image {
							asset {
								fluid {
									src
									aspectRatio
									base64
									sizes
									srcSet
									srcSetWebp
									srcWebp
								}
							}
						}
					}
					_rawText(resolveReferences: { maxDepth: 10 })
					link {
						label
						_rawData(resolveReferences: { maxDepth: 10 })
					}
				}
			}
			box {
				title
				link {
					label
					_rawData(resolveReferences: { maxDepth: 10 })
				}
				_rawText(resolveReferences: { maxDepth: 10 })
			}
		}
	}
`

export default IndexPage
